import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';
import Layout from "../components/Layout";
import bgImage from '../assets/images/home-background.jpg'

const IndexStyles = styled.div`

  max-width: 850px;
  margin: auto;
  padding: 80px 40px 20px 40px;
  z-index: 2;
    
  :before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.125;
    background-image: url(${bgImage});
    background-repeat: repeat;
    background-position: 50% 0;
    background-size: cover;
    background-attachment: fixed;
  }


  @media screen and (max-width: 1000px) {
    padding: 60px 20px 20px 20px;
  }

  @media screen and (max-width: 400px) {
    h1 {
      margin: 0;
    }
  }
`;

export default function Home() {
  return <Layout>
    <IndexStyles>
      <h1>Global Business Translation and Localization Services</h1>
      <p>Since 1997, Transet Multilingual Services have been providing translation and language service needs to companies and associations across North America. From large projects such as product manuals, web site translation and software documentation to a simple bilingual business card, we strive to provide the most accurate and highest quality translation to ensure that your message is clear, concise and culturally sensitive to your target audience.</p>
      <p>To accomplish this, it is our company policy that we only assign projects to our native-tongue translators. This ensures linguistic style, vocabulary and cultural specifics are captured in the translated work. Not only will your project be translated by experienced, native linguists but the teams assigned will also have expertise in your subject area or industry. Our expertise covers many fields including General, Medical, Life Science, Business, Technology, Advertising and Legal to name a few. We can translate your material to mirror your source (usually English) copy or we can provide copy writing services for your target market.</p>
      <p>Aside from our professional Documentation translation services, we also support other language services including:</p>
      <ul>
        <li>Website Localization</li>
        <li>Multilingual Publishing</li>
      </ul>
      <p>Please see the <Link to="/services">Services</Link> section for more information.</p>
    </IndexStyles>
  </Layout>
}
